<template>
  <c-flex
    position="relative"
    width="100%"
    height="100%"
    flex-direction="column"
    justify-content="center"
    margin-inline="auto"
    :background-color="['#F2F2F2', '#FFF']"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :padding="['1rem 1rem 80px 1rem', '1.5rem']"
    :min-height="['calc(100vh - 62px)', 'auto']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="breadcrumbLabel"
      />
    </Portal>

    <c-box v-if="isLoadingData">
      <c-flex
        width="100%"
        flex-direction="column"
        justify-content="center"
        align-items="center"
        padding="128px 24px"
      >
        <c-spinner
          thickness="4px"
          speed="0.65s"
          empty-color="green.200"
          color="primary.400"
          size="xl"
          margin-bottom="16px"
        />
        <c-text
          color="primary.400"
          font-weight="500"
        >
          Memuat data Rencana Makan...
        </c-text>
      </c-flex>
    </c-box>

    <EmptyState
      v-else-if="!data?.mealPlanSchedules?.length"
      title="Rencana makanmu masih kosong"
      description="Ahli gizi kami masih merencanakan panduan makan terbaik buat kamu. Mohon ditunggu, ya!"
      button-text="Beranda"
      button-route="client.index"
    />
    <c-box
      v-else
      width="100%"
      :background-color="['#FFF', 'transparent']"
      :padding="['16px', '0px']"
      margin-bottom="auto"
    >
      <c-flex
        width="100%"
        justify-content="space-between"
        margin-bottom="16px"
      >
        <c-flex
          flex-grow="1"
          gap="16px"
          align-items="center"
        >
          <c-button
            :display="['none', 'flex']"
            variant="ghost"
            padding="0px"
            width="30px"
            height="30px"
            min-width="30x"
            align-items="center"
            @click="$router.back()"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-chevron-left.svg')"
              height="20px"
              width="20px"
            />
          </c-button>
          <c-text
            :font-size="['18px', '20px']"
            font-weight="600"
            color="neutral.superDarkGray"
          >
            {{ selectedFilter?.label }} - {{ parseData[selectedFilter?.id]?.caloriesAmount || 0 }}kkal
          </c-text>
        </c-flex>
        <c-text
          :font-size="['14px', '18px']"
          color="neutral.888888"
        >
          {{ formatTime(parseData[selectedFilter?.id]?.startTime) }} - {{ formatTime(parseData[selectedFilter?.id]?.endTime) }}
        </c-text>
      </c-flex>
      <c-box
        class="client_meal-plan_meal-plan-schedule_detail_2"
        :class="isTourActive ? 'pointer-events-none' : ''"
      >
        <ChipFilter
          :options="optionFilters"
          :selected-filter="selectedFilter"
          justify-content="flex-start"
          :min-width="['30px', '40px']"
          @on-change-filter="onChangeFilter"
        />
      </c-box>
      <c-box
        v-if="parseData[selectedFilter?.id]"
        margin-top="16px"
      >
        <AccordionCustom
          v-if="!parseData[selectedFilter?.id]?.isSkipped"
        >
          <AccordionCustomItem
            v-for="(item, idx) in parseData[selectedFilter?.id]?.mealComposition"
            :key="idx"
            :label="item?.foodGroup"
            :sub-label="`${item?.portion} porsi`"
            :is-disabled="false"
            default-is-open
            class="client_meal-plan_meal-plan-schedule_detail_3"
          >
            <c-box
              v-if="item?.mealItems?.length >= 1 || item?.additionalMealItems?.length >= 1"
              class="meal-plan-panel"
            >
              <c-text
                :font-size="['12px', '16px']"
                color="primary.400"
              >
                Pilihan golongan makanan
              </c-text>
  
              <c-grid
                margin-top="8px"
                :template-columns="['repeat(3, 1fr)', 'repeat(5, 1fr)']"
                align-items="flex-start"
                :gap="['2', '6']"
                :class="isTourActive ? 'pointer-events-none' : ''"
              >
                <c-flex
                  v-for="mealItem in item?.mealItems"
                  :key="mealItem?.id"
                  flex-direction="column"
                  justify-content="center"
                  align-items="center"
                  w="100%"
                  min-height="10px"
                  margin-bottom="auto"
                  background-color="neutral.white"
                  border-radius="8px"
                  cursor="pointer"
                  @click="onOpenDetailMenu(mealItem, item?.portionDecimal, true)"
                >
                  <c-box
                    position="relative"
                    border-radius="8px"
                    object-fit="cover"
                    overflow="hidden"
                    margin-bottom="4px"
                    box-shadow="0px 0px 8px 5px #0000001F"
                  >
                    <c-image
                      :src="mealItem?.photoUrl || 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/ilustrasi%20general.webp?updatedAt=1716858300250'"
                      object-fit="cover"
                      size="72px"
                    />
                    <c-box
                      position="absolute"
                      width="100%"
                      bottom="0"
                      background-color="#88888899"
                      color="#FFF"
                      font-size="10px"
                      text-align="center"
                    >
                      Lihat detail
                    </c-box>
                  </c-box>
                  <c-text
                    font-size="14px"
                    text-align="center"
                  >
                    {{ mealItem?.foodName }}
                  </c-text>
                  <c-text
                    color="neutral.888888"
                    :font-size="['12px', '14px']"
                    text-align="center"
                  >
                    {{ calcHouseholdMeasurement(mealItem?.householdMeasurementPerPortion, item?.portionDecimal) }}
                    {{ mealItem?.householdMeasurementUnitPerPortion }}
                  </c-text>
                </c-flex>
                <c-flex
                  v-for="mealItem in item?.additionalMealItems"
                  :key="mealItem?.id"
                  flex-direction="column"
                  justify-content="center"
                  align-items="center"
                  w="100%"
                  min-height="10px"
                  border-radius="8px"
                  cursor="pointer"
                  @click="onOpenDetailMenu(mealItem, 0, false)"
                >
                  <c-box
                    position="relative"
                    border-radius="8px"
                    object-fit="cover"
                    overflow="hidden"
                    margin-bottom="4px"
                    box-shadow="0px 0px 8px 5px #0000001F"
                  >
                    <c-image
                      src="'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/ilustrasi%20general.webp?updatedAt=1716858300250'"
                      object-fit="cover"
                      size="72px"
                    />
                    <c-box
                      position="absolute"
                      width="100%"
                      bottom="0"
                      background-color="#88888899"
                      color="#FFF"
                      font-size="10px"
                      text-align="center"
                    >
                      Lihat detail
                    </c-box>
                  </c-box>
                  <c-text font-size="14px">
                    {{ mealItem?.foodName }}
                  </c-text>
                  <c-text
                    color="neutral.888888"
                    :font-size="['12px', '14px']"
                  >
                    {{ calcHouseholdMeasurement(mealItem?.householdMeasurement, item?.portionDecimal) }}
                    {{ mealItem?.householdMeasurementUnit }}
                  </c-text>
                </c-flex>
              </c-grid>
            </c-box>
            <NoData
              v-else
              text="Tidak ada data"
            />
          </AccordionCustomItem>
        </AccordionCustom>
        <NoData
          v-else
          text="Jam makan diskip"
        />
        <c-grid
          w="100%"
          :template-columns="['repeat(2, 1fr)']"
          :gap="['16px']"
        >
          <c-button
            variant="outline"
            variant-color="primary"
            :height="['34px', '50px']"
            border-radius="50px"
            :font-size="['16px', '18px']"
            font-weight="500"
            padding-inline="8px"
            :gap="['8px', '20px']"
            :is-disabled="isFirstStep"
            @click="handlePrevious"
          >
            <c-box
              as="span"
              flex-shrink="0"
              :display="['block', 'none']"
              :width="['18px', '20px']"
              :height="['18px', '20px']"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-left.svg')"
                height="100%"
                width="100%"
                fill="#008C81"
              />
            </c-box>
            Sebelumnya
          </c-button>
          <c-button
            variant="solid"
            variant-color="primary"
            :height="['34px', '50px']"
            border-radius="50px"
            :font-size="['16px', '18px']"
            font-weight="500"
            padding-inline="8px"
            :gap="['8px', '20px']"
            :is-disabled="isLastStep"
            @click="handleNext"
          >
            Selanjutnya
            <c-box
              as="span"
              :display="['block', 'none']"
              flex-shrink="0"
              :width="['18px', '20px']"
              :height="['18px', '20px']"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-right.svg')"
                height="100%"
                width="100%"
                fill="#FFFFFF"
              />
            </c-box>
          </c-button>
        </c-grid>
      </c-box>
      <NoData
        v-else
        text="Tidak ada data"
      />
    </c-box>

    <BaseModal
      :is-open="isOpenDetailMenu && !['sm', 'md'].includes(currentBreakpoint)"
      :close-on-overlay-click="false"
      :with-button-close="false"
      :has-footer="selectedMenu?.isFromMealGlossary ? true : false"
      size="700px"
    >
      <template #header>
        <c-flex
          position="relative"
          width="100%"
          justify-content="center"
          align-items="center"
          :padding-block="['12px', '16px']"
          border-bottom-width="1px"
        >
          <c-text
            :font-size="['18px', '24px']"
            font-weight="600"
            color="neutral.black"
            text-align="center"
          >
            Detail Menu
          </c-text>
          <c-button
            position="absolute"
            :right="['12px', '20px']"
            variant="ghost"
            width="30px"
            min-width="30px"
            height="30px"
            margin="0"
            padding="0"
            @click="onCloseDetailMenu"
          >
            <c-icon
              name="close"
              size="16px"
            />
          </c-button>
        </c-flex>
      </template>
      <template #body>
        <c-box
          width="100%"
          max-height="640px"
          overflow="auto"
        >
          <c-box
            margin-inline="auto"
            max-width="700px"
            :padding-inline="['10px', '20px']"
            padding-bottom="16px"
          >
            <c-flex
              flex-direction="column"
              width="100%"
              padding-block="16px"
              align-items="center"
              justify-content="center"
              gap="12px"
              :border-bottom-width="selectedMenu?.isFromMealGlossary ? '1px' : '0px'"
              border-bottom-color="#E2E8F0"
              border-bottom-style="solid"
            >
              <c-box
                position="relative"
                border-radius="8px"
                object-fit="cover"
                overflow="hidden"
                margin-bottom="4px"
                box-shadow="0px 0px 8px 3px #0000001F"
              >
                <c-image
                  :src="selectedMenu ? selectedMenu?.photoUrl : 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/ilustrasi%20general.webp?updatedAt=1716858300250'"
                  :width="['220px', '300px']"
                  :height="['220px', '300px']"
                  object-fit="cover"
                  object-position="center"
                />
              </c-box>
              <c-flex
                gap="6px" 
                justify-content="center"
                align-items="flex-end"
              >
                <c-box
                  :font-size="['14px', '18px']"
                  font-weight="500"
                  color="neutral.superDarkGray"
                >
                  {{ selectedMenu?.foodName }}
                </c-box>
                <c-box
                  :font-size="['12px', '16px']"
                  color="neutral.888888"
                >
                  {{ selectedMenu?.portionDetail }}
                </c-box>
              </c-flex>
            </c-flex>
            <c-box
              v-if="selectedMenu?.isFromMealGlossary"
              margin-top="16px"
            >
              <c-text
                :font-size="['12px', '16px']"
                color="primary.400"
              >
                Pilihan Jenis Makanan
              </c-text>
              <c-grid
                v-if="isLoadingRecommendationMealGlossary"
                margin-top="8px"
                :template-columns="['repeat(4, 1fr)']"
                :gap="['2', '4']"
              >
                <c-flex
                  v-for="item in 4"
                  :key="item"
                  flex-direction="column"
                  justify-content="center"
                  align-items="center"
                  w="100%"
                  min-height="10px"
                  margin-bottom="auto"
                  cursor="wait"
                  background="neutral.white"
                  border-radius="8px"
                  :_hover="{
                    transition: 'background 0.25s',
                    background: 'neutral.superLightGray',
                  }"
                >
                  <c-box position="relative">
                    <c-box
                      :width="['72px']"
                      :height="['72px']"
                      mb="4px"
                      border-radius="6px"
                      margin-bottom="4px"
                    >
                      <vue-skeleton-loader
                        type="rect"
                        width="100%"
                        height="100%"
                        animation="fade"
                        rounded
                      />
                    </c-box>
                  </c-box>
                  <c-box
                    :w="['80%']"
                    :h="['16px', '18px']"
                    mb="4px"
                  >
                    <vue-skeleton-loader
                      type="rect"
                      width="100%"
                      height="100%"
                      animation="fade"
                      rounded
                    />
                  </c-box>
                  <c-box
                    :w="['40%']"
                    :h="['16px', '18px']"
                  >
                    <vue-skeleton-loader
                      type="rect"
                      width="100%"
                      height="100%"
                      animation="fade"
                      rounded
                    />
                  </c-box>
                </c-flex>
              </c-grid>
              <c-grid
                v-else-if="recommendationMealGlossary?.length > 0"
                margin-top="8px"
                :template-columns="['repeat(4, 1fr)']"
                align-items="flex-start"
                :gap="['2', '4']"
              >
                <c-flex
                  v-for="mealItem in recommendationMealGlossary"
                  :key="mealItem?.id"
                  flex-direction="column"
                  justify-content="center"
                  align-items="center"
                  w="100%"
                  min-height="10px"
                  margin-bottom="auto"
                  cursor="pointer"
                  background="neutral.white"
                  border-radius="8px"
                  :_hover="{
                    transition: 'background 0.25s',
                    background: 'neutral.superLightGray',
                  }"
                  @click="$router.push({
                    name: 'client.glossaries-detail',
                    params: {
                      glossaryId: mealItem?.id,
                    },
                  })"
                >
                  <c-box
                    position="relative"
                    border-radius="8px"
                    object-fit="cover"
                    overflow="hidden"
                    margin-bottom="4px"
                    box-shadow="0px 0px 8px 3px #0000001F"
                  >
                    <c-image
                      :src="mealItem?.photoUrl || 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/ilustrasi%20general.webp?updatedAt=1716858300250'"
                      object-fit="cover"
                      size="72px"
                    />
                  </c-box>
                  <c-text
                    font-size="14px"
                    text-align="center"
                  >
                    {{ mealItem?.foodName }}
                  </c-text>
                  <c-text
                    color="neutral.888888"
                    :font-size="['12px', '14px']"
                    text-align="center"
                  >
                    {{ calcHouseholdMeasurement(mealItem?.householdMeasurementPerPortion, selectedMenu?.portionDecimal) }}
                    {{ mealItem?.householdMeasurementUnitPerPortion }}
                  </c-text>
                </c-flex>
              </c-grid>
              <c-box
                v-else
                text-align="center"
                padding-block="20px"
                :font-size="['14px', '16px']"
                font-weight="400"
                color="neutral.888888"
              >
                Tidak ada data jenis makanan terkait.
              </c-box>
            </c-box>
          </c-box>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-inline="16px"
          padding-bottom="16px"
          text-align="center"
        >
          <BaseButton
            variant="outlined"
            min-width="250px"
            @click="$router.push({
              name: 'client.glossaries-detail',
              params: {
                glossaryId: selectedMenu?.id,
              },
            })"
          >
            Lihat Informasi Nilai Gizi
          </BaseButton>
        </c-box>
      </template>
    </BaseModal>

    <c-drawer
      placement="bottom"
      :on-close="onCloseDetailMenu"
      :is-open="isOpenDetailMenu && ['sm', 'md'].includes(currentBreakpoint)"
    >
      <c-drawer-overlay />
      <c-drawer-content
        border-top-left-radius="16px"
        border-top-right-radius="16px"
      >
        <c-drawer-header 
          border-bottom-width="1px"
          padding-inline="12px"
          padding-block="12px"
        >
          <c-flex
            position="relative"
            justify-content="center"
            align-items="center"
          >
            <c-text
              :font-size="['16px', '24px']"
              font-weight="500"
              color="neutral.black"
              text-align="center"
            >
              Detail Menu
            </c-text>
            <c-button
              position="absolute"
              :right="['0px', '20px']"
              variant="ghost"
              width="30px"
              min-width="30px"
              height="30px"
              margin="0"
              padding="0"
              @click="onCloseDetailMenu"
            >
              <c-icon
                name="close"
                :size="['13px','17px']"
              />
            </c-button>
          </c-flex>
        </c-drawer-header>
        <c-drawer-body
          padding-inline="12px"
          padding-block="0px"
        >
          <c-box
            width="100%"
            max-height="640px"
            overflow="auto"
          >
            <c-box
              margin-inline="auto"
              max-width="700px"
              :padding-inline="['0px', '20px']"
              padding-bottom="16px"
            >
              <c-flex
                flex-direction="column"
                width="100%"
                padding-block="16px"
                align-items="center"
                justify-content="center"
                gap="12px"
                :border-bottom-width="selectedMenu?.isFromMealGlossary ? '1px' : '0px'"
                border-bottom-color="#E2E8F0"
                border-bottom-style="solid"
              >
                <c-box
                  position="relative"
                  border-radius="8px"
                  object-fit="cover"
                  overflow="hidden"
                  margin-bottom="4px"
                  box-shadow="0px 0px 8px 3px #0000001F"
                >
                  <c-image
                    :src="selectedMenu ? selectedMenu?.photoUrl : 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/ilustrasi%20general.webp?updatedAt=1716858300250'"
                    :width="['220px', '300px']"
                    :height="['220px', '300px']"
                    object-fit="cover"
                    object-position="center"
                  />
                </c-box>
                <c-flex
                  gap="6px" 
                  justify-content="center"
                  align-items="flex-end"
                >
                  <c-box
                    :font-size="['14px', '18px']"
                    font-weight="500"
                    color="neutral.superDarkGray"
                  >
                    {{ selectedMenu?.foodName }}
                  </c-box>
                  <c-box
                    :font-size="['12px', '16px']"
                    color="neutral.888888"
                  >
                    {{ selectedMenu?.portionDetail }}
                  </c-box>
                </c-flex>
              </c-flex>
              <c-box
                v-if="selectedMenu?.isFromMealGlossary"
                margin-top="16px"
              >
                <c-text
                  :font-size="['12px', '16px']"
                  color="primary.400"
                >
                  Pilihan Jenis Makanan
                </c-text>
                <c-grid
                  v-if="isLoadingRecommendationMealGlossary"
                  margin-top="8px"
                  :template-columns="['repeat(4, 1fr)']"
                  :gap="['2', '4']"
                >
                  <c-flex
                    v-for="item in 4"
                    :key="item"
                    flex-direction="column"
                    justify-content="center"
                    align-items="center"
                    w="100%"
                    min-height="10px"
                    margin-bottom="auto"
                    cursor="wait"
                    background="neutral.white"
                    border-radius="8px"
                    :_hover="{
                      transition: 'background 0.25s',
                      background: 'neutral.superLightGray',
                    }"
                  >
                    <c-box position="relative">
                      <c-box
                        :width="['72px']"
                        :height="['72px']"
                        mb="4px"
                        border-radius="6px"
                        margin-bottom="4px"
                      >
                        <vue-skeleton-loader
                          type="rect"
                          width="100%"
                          height="100%"
                          animation="fade"
                          rounded
                        />
                      </c-box>
                    </c-box>
                    <c-box
                      :w="['80%']"
                      :h="['16px', '18px']"
                      mb="4px"
                    >
                      <vue-skeleton-loader
                        type="rect"
                        width="100%"
                        height="100%"
                        animation="fade"
                        rounded
                      />
                    </c-box>
                    <c-box
                      :w="['40%']"
                      :h="['16px', '18px']"
                    >
                      <vue-skeleton-loader
                        type="rect"
                        width="100%"
                        height="100%"
                        animation="fade"
                        rounded
                      />
                    </c-box>
                  </c-flex>
                </c-grid>
                <c-grid
                  v-else-if="recommendationMealGlossary?.length > 0"
                  margin-top="8px"
                  :template-columns="['repeat(4, 1fr)']"
                  align-items="flex-start"
                  :gap="['2', '4']"
                >
                  <c-flex
                    v-for="mealItem in recommendationMealGlossary"
                    :key="mealItem?.id"
                    flex-direction="column"
                    justify-content="center"
                    align-items="center"
                    w="100%"
                    min-height="10px"
                    margin-bottom="auto"
                    cursor="pointer"
                    background="neutral.white"
                    border-radius="8px"
                    :_hover="{
                      transition: 'background 0.25s',
                      background: 'neutral.superLightGray',
                    }"
                    @click="$router.push({
                      name: 'client.glossaries-detail',
                      params: {
                        glossaryId: mealItem?.id,
                      },
                    })"
                  >
                    <c-box
                      position="relative"
                      border-radius="8px"
                      object-fit="cover"
                      overflow="hidden"
                      margin-bottom="4px"
                      box-shadow="0px 0px 8px 3px #0000001F"
                    >
                      <c-image
                        :src="mealItem?.photoUrl || 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/ilustrasi%20general.webp?updatedAt=1716858300250'"
                        object-fit="cover"
                        size="72px"
                      />
                    </c-box>
                    <c-text
                      font-size="14px"
                      text-align="center"
                    >
                      {{ mealItem?.foodName }}
                    </c-text>
                    <c-text
                      color="neutral.888888"
                      :font-size="['12px', '14px']"
                      text-align="center"
                    >
                      {{ calcHouseholdMeasurement(mealItem?.householdMeasurementPerPortion, selectedMenu?.portionDecimal) }}
                      {{ mealItem?.householdMeasurementUnitPerPortion }}
                    </c-text>
                  </c-flex>
                </c-grid>
                <c-box
                  v-else
                  text-align="center"
                  padding-block="20px"
                  :font-size="['14px', '16px']"
                  font-weight="400"
                  color="neutral.888888"
                >
                  Tidak ada data jenis makanan terkait.
                </c-box>
              </c-box>
            </c-box>
          </c-box>
          <c-box
            v-if="selectedMenu?.isFromMealGlossary"
            width="100%"
            padding-inline="16px"
            padding-bottom="16px"
            text-align="center"
          >
            <BaseButton
              variant="outlined"
              min-width="250px"
              height="42px"
              border-radius="42px"
              @click="$router.push({
                name: 'client.glossaries-detail',
                params: {
                  glossaryId: selectedMenu?.id,
                },
              })"
            >
              Lihat Informasi Nilai Gizi
            </BaseButton>
          </c-box>
        </c-drawer-body>
      </c-drawer-content>
    </c-drawer>
  </c-flex>
</template>

<script>
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import { mealTileIdToImageUrl, mealTileIdToLabel } from '@/constants/meal-time'
import { mealPlanTour } from '@/constants/meal-plans'
import EmptyState from '@/views/client/meal-plan/widgets/empty-state.vue'
import ChipFilter from '@/components/chip-filter.vue'
import AccordionCustomItem from '@/components/accordion-custom/item.vue'
import AccordionCustom from '@/components/accordion-custom/index.vue'
import NoData from '@/components/no-data.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseButton from '@/components/elements/base-button.vue'
import VueSkeletonLoader from 'skeleton-loader-vue'
import {
  reqClient_mealPlans_mealGlossary_simillarItems,
} from '@/requests/dietela-api/client/meal-plan'
import dayjs from 'dayjs'
import mixinsCheckBreakpoints from '@/mixins/mixins-check-breakpoints'
import { convertToFraction } from '@/utils/fraction'

let day = dayjs

export default {
  components: {
    BreadcrumbPath,
    EmptyState,
    ChipFilter,
    AccordionCustomItem,
    AccordionCustom,
    NoData,
    BaseModal,
    BaseButton,
    VueSkeletonLoader,
  },
  mixins: [mixinsCheckBreakpoints()],
  beforeRouteLeave(to, from, next) {
    if (!this.isTourActive) {
      next()
    }
  },
  props: {
    isLoadingData: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    activeProgram: {
      type: [Object],
      default: () => {},
    },
    isTourActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedFilter: {},
      isOpenDetailMenu: false,
      selectedMenu: null,
      recommendationMealGlossary: [],
      isLoadingRecommendationMealGlossary: false,
      
      tourOptions: {
        debug: true,
        useKeyboardNavigation: false,
        startTimeout: 500,
      },
      tourSteps: [],
    }
  },
  computed: {
    constants() {
      return {
        mealTileIdToLabel,
        mealTileIdToImageUrl,
        mealPlanTour,
      }
    },
    breadcrumbLabel() {
      return [
        {
          label: 'Dashboard',
          href: '/',
        },
        {
          label: 'Panduan Makan',
          href: '/meal-plan',
        },
        {
          label: 'Rencana Makan',
          href: `/meal-plan/${this.$route.params.month}/meal-plan-schedule`,
        },
        {
          label: 'Jam Makan',
          isCurrent: true,
        },
      ]
    },
    isFirstStep() {
      return this.activeStepIndex === 0
    },
    isLastStep() {
      return this.activeStepIndex === this.optionFiltersForNavigation.length - 1
    },
    activeStepIndex() {
      return this.optionFiltersForNavigation.findIndex((it) => it.id === this.selectedFilter.id)
    },
    optionFiltersForNavigation() {
      return this.optionFilters.filter((item) => !item.disabled)
    },
    optionFilters() {
      return [
        {
          id: 'breakfast',
          label: 'Sarapan',
          disabled: this.parseData['breakfast']?.isSkipped,
        },
        {
          id: 'morningSnack',
          label: 'Snack Pagi',
          disabled: this.parseData['morningSnack']?.isSkipped,
        },
        {
          id: 'lunch',
          label: 'Makan Siang',
          disabled: this.parseData['lunch']?.isSkipped,
        },
        {
          id: 'afternoonSnack',
          label: 'Snack Siang',
          disabled: this.parseData['afternoonSnack']?.isSkipped,
        },
        {
          id: 'dinner',
          label: 'Makan Malam',
          disabled: this.parseData['dinner']?.isSkipped,
        },
        {
          id: 'nightSnack',
          label: 'Snack Malam',
          disabled: this.parseData['nightSnack']?.isSkipped,
        },
      ]
    },
    parseData() {
      const tes = this.data?.mealPlanSchedules?.reduce((acc, curr) => {
        if (curr?.mealTime) {
          acc[curr?.mealTime] = curr
        }
        return acc
      }, {})
      return tes
    },
  },
  watch: {
    parseData: {
      immediate: true,
      handler(val) {
        if(val) {
          if (this.$route.query?.mealTime) {
            this.selectedFilter = this.optionFilters.find((item) => item.id === this.$route.query.mealTime)
          } else {
            this.$router.replace({
              ...this.$route,
              query: {
                ...this.$route?.query,
                mealTime: this.optionFilters[0]?.id,
              },
            })
            this.selectedFilter = this.optionFilters[0]
          }
        }
      },
    },
  },
  methods: {
    convertToFraction,
    async handlePrevious() {
      this.$router.replace({
        ...this.$route,
        query: {
          ...this.$route.query,
          mealTime: this.optionFiltersForNavigation[this.activeStepIndex - 1]?.id,
        },
      })
    },
    async handleNext() {
      this.$router.replace({
        ...this.$route,
        query: {
          ...this.$route.query,
          mealTime: this.optionFiltersForNavigation[this.activeStepIndex + 1]?.id,
        },
      })
    },
    onChangeFilter(char) {
      if (this.selectedFilter?.id === char?.id) return
      this.$router.replace({
        ...this.$route,
        query: {
          mealTime: char.id,
        },
      })
      this.selectedFilter = char
    },
    formatTime(time24) {
      if (!time24) return ''
      return day(time24, 'HH:mm').format('h A')
    },
    onOpenDetailMenu(menu, portionDecimal, isFromMealGlossary) {
      this.recommendationMealGlossary = []
      this.selectedMenu = {
        ...menu,
        portionDetail: isFromMealGlossary 
          ? `${this.calcHouseholdMeasurement(menu?.householdMeasurementPerPortion, portionDecimal)} ${menu?.householdMeasurementUnitPerPortion}`
          : `${this.calcHouseholdMeasurement(menu?.householdMeasurement, portionDecimal)} ${menu?.householdMeasurementUnit}`,
        isFromMealGlossary,
        portionDecimal,
      }
      this.isOpenDetailMenu = true
      if (isFromMealGlossary) {
        this.getRecommendationMealGlossary(menu?.id)
      }
    },
    onCloseDetailMenu() {
      this.isOpenDetailMenu = false
      this.selectedMenu = null
    },
    async getRecommendationMealGlossary(mealGlossaryId) {
      this.isLoadingRecommendationMealGlossary = true
      const res = await reqClient_mealPlans_mealGlossary_simillarItems(this.$store.getters.axios,
        {
          mealGlossaryId,
        },
      )
      this.recommendationMealGlossary = res?.data?.data
      this.isLoadingRecommendationMealGlossary = false
    },
    calcHouseholdMeasurement(meal, portionDecimal) {
      let size = meal

      const parseSize = size ? parseFloat(size) : 0
      if (portionDecimal) {
        const result = parseFloat(portionDecimal) * parseSize
  
        if (Number.isInteger(result)) {
          return result
        }
  
        const formattedNumber = result
        return this.convertToFraction(formattedNumber)
      }
      return parseSize
    },
  },
}
</script>

<style scoped>
::v-deep [data-chakra-component="CCollapse"]:has([data-chakra-component="CAccordionPanel"]) {
  overflow: unset !important;
}
::v-deep [data-chakra-component="CAccordionPanel"]:has(.meal-plan-panel) {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  background: white;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
</style>